import { template as template_ca2f9d4292b14389ae4c3758b476ea27 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_ca2f9d4292b14389ae4c3758b476ea27(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
