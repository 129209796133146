import { template as template_57e04ce9fadd4847ac19ca93a43c451c } from "@ember/template-compiler";
const FKLabel = template_57e04ce9fadd4847ac19ca93a43c451c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
