import { template as template_4d6a407961b148569c51ab90df961b28 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4d6a407961b148569c51ab90df961b28(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
