import { template as template_c72b39a2e2b741e681c742096d41388b } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_c72b39a2e2b741e681c742096d41388b(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_c72b39a2e2b741e681c742096d41388b(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_c72b39a2e2b741e681c742096d41388b(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
