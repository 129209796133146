import { template as template_1de1b106e8504890a30d7aba3831a238 } from "@ember/template-compiler";
const WelcomeHeader = template_1de1b106e8504890a30d7aba3831a238(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
